import styled from 'styled-components'
import tokens from '@veneer/tokens'

export const CardContainer = styled.div`
  && {
    border: none;
  }
  > .css-gslkub {
    border: none;
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 5px;
  background: transparent;
  padding: 40px 40px 60px 40px;

  @media (max-width: 375px) {
    padding: 24px 16px 60px 16px;
  }
`

export const CardHeading = styled.div`
  align-items: baseline;
  padding-bottom: ${tokens.space6};
`
export const MemberTitle = styled.h3`
  display: flex;
  align-items: center;
  color: ${tokens.colorGray10};
`
export const Text = styled.span`
  display: flex;
  align-items: center;
  color: ${tokens.colorGray10};
  padding-top: ${tokens.space6};
`
export const AddMemberTitle = styled.span`
  display: flex;
  padding-top: ${tokens.space4};
`
