import styled from 'styled-components'
import tokens from '@veneer/tokens'

export const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid ${tokens.colorGray3};
  background: ${tokens.colorWhite};
  border-radius: ${tokens.borderRadius3};
  color: ${tokens.colorGray10};
  min-height: 60vh;
  padding: ${tokens.space8};
  text-align: center;

  @media (max-width: 375px) {
    padding: ${tokens.space4};
  }
`
