import React, { useEffect, useState } from 'react'
import OnboardingService from '../src/components/OnboardingService'
import { ShellProps } from '../src/types/shell'
import { MfePropsType } from '../src/types/mfeProps'
import projectNames from '../src/configs/projectNames'
import { TranslatorFunctionType } from '../src/types/localization'
import resources from '../src/assets/locale'
import App from '../src/App'

declare global {
  interface Window {
    Shell: ShellProps
  }
}

type AppProps = {
  country?: string
  language?: string
}

/**
 * @function Root Main function
 * @param props
 * @returns
 */
export default function Root({
  // used only to test onboarding services
  useOnboardinServiceExample,
  ...props
}: MfePropsType & AppProps) {
  // You can find all Shell´s props here. See "../src/types/shell" for more details.
  const [t, setT] = useState<TranslatorFunctionType>()
  const interfaces = window.Shell.v1
  const Component = useOnboardinServiceExample ? OnboardingService : App

  // Create a translator function by providing the resource files
  useEffect(() => {
    interfaces.localization
      .createTranslatorFunction(resources)
      .then((v) => setT(() => v))
  }, [interfaces])

  // Wait for the translator function creation to show the content
  if (!t) {
    return null
  }

  // You can find the "stack" information by props
  // const stack = props.stack.
  return (
    <section
      className={`${projectNames.namespace}`}
      id={projectNames.packageJsonName}
    >
      <Component {...{ ...props, ...interfaces, t }} />
    </section>
  )
}
