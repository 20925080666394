import React from 'react'
import PropTypes from 'prop-types'
import { ModalContainer } from './styles'

export const Modal = ({
  id,
  onClose,
  show,
  title,
  footer,
  children,
  align
}) => (
  <ModalContainer
    className="title-small"
    density="high"
    id={id}
    onClose={onClose}
    show={show}
    title={title}
    footer={footer}
    align={align}
  >
    {children}
  </ModalContainer>
)

Modal.defaultProps = {
  align: 'start'
}

Modal.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  align: PropTypes.string,
  footer: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
}

export default Modal
