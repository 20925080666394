import React, { useState, useEffect } from 'react'
import Accordion from '@veneer/core/dist/scripts/accordion'
import {
  AccordionContainer,
  AccordionTitle,
  MobileIconTrash,
  ItemContainer,
  ItemLabel,
  ItemValue
} from '../UserInfo/style'
import Avatar from '@veneer/core/dist/scripts/avatar'
import ProgressIndicator from '@veneer/core/dist/scripts/progress_indicator'
import { useTranslator } from '../../../hooks/useTranslator'
import { IconTrash } from '@veneer/core/dist/scripts/icons'

const UserInfoAccordion = ({ users, trashIconHandler, isLoading }) => {
  const t = useTranslator()

  const [accordionItems, setAccordionItems] = useState([])

  const handleExpand = (_event: unknown, index: number) => {
    const updatedData = accordionItems.map((d) => d)
    updatedData[index].expanded = true
    setAccordionItems(updatedData)
  }

  const handleCollapse = (_event: unknown, index: number) => {
    const updatedData = accordionItems.map((d) => d)
    updatedData[index].expanded = false
    setAccordionItems(updatedData)
  }

  useEffect(() => {
    if (users) {
      setAccordionItems(
        users.map((user) => ({
          content: (
            <>
              <ItemContainer className="label-small">
                <ItemLabel>{t('hpxUsers.tableHeaders.header2')}</ItemLabel>
                <ItemValue>{user.email}</ItemValue>
              </ItemContainer>
              <ItemContainer className="label-small">
                <ItemLabel>{t('hpxUsers.tableHeaders.header4')}</ItemLabel>
                <ItemValue>{user.roleText}</ItemValue>
              </ItemContainer>
              <ItemContainer className="label-small">
                <ItemLabel>{t('hpxUsers.tableHeaders.header3')}</ItemLabel>
                <ItemValue>{user.statusText}</ItemValue>
              </ItemContainer>
              {user.role == 'User' && (
                <MobileIconTrash
                  appearance="ghost"
                  leadingIcon={<IconTrash />}
                  disabled={user.role == 'Admin'}
                  onClick={() => trashIconHandler(user)}
                />
              )}
            </>
          ),
          header: {
            startArea: (
              <Avatar
                label={
                  (user.givenName &&
                    user.givenName[0].toUpperCase() + user.familyName &&
                    user.familyName[0].toUpperCase()) ||
                  (user.email && user.email[0].toUpperCase())
                }
                size={36}
              />
            ),
            centralArea: (
              <AccordionTitle className="label">
                {`${user.givenName || ''} ${user.familyName || ''}`.trim() ||
                  '-'}
              </AccordionTitle>
            )
          }
        })) || []
      )
    }
  }, [t, trashIconHandler, users])

  return (
    <AccordionContainer>
      {isLoading ? (
        <ProgressIndicator appearance="linear" behavior="indeterminate" />
      ) : (
        <Accordion
          appearance="dropShadow"
          gutter={12}
          behavior="singleExpand"
          items={accordionItems}
          onExpand={handleExpand}
          onCollapse={handleCollapse}
        />
      )}
    </AccordionContainer>
  )
}

export default UserInfoAccordion
