import React from 'react'
import { CardsContainer } from '../NoUsers/styles'
import { Container, CardHeading, MemberTitle, Text } from '../style'

import { useTranslator } from '../../../hooks/useTranslator'

const NoUsers = () => {
  const t = useTranslator()
  return (
    <>
      <Container>
        <CardHeading>
          <MemberTitle className="title-small">
            {t('hpxUsers.topOfPage.mainHeaderV2')}
          </MemberTitle>
          <Text className="body">{t('hpxUsers.noUsers.header1')}</Text>
        </CardHeading>
        <CardsContainer className="body">
          {t('hpxUsers.noUsers.header2')}
        </CardsContainer>
      </Container>
    </>
  )
}
export default NoUsers
