import styled from 'styled-components'
import ProgressIndicator from '@veneer/core/dist/scripts/progress_indicator'
import tokens from '@veneer/tokens'

export const LoadingContainer = styled.div`
  background-color: transparent;
  padding: 40px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 190px);

  @media (max-width: 375px) {
    padding: ${tokens.space6} ${tokens.space4};
  }
`

export const LoadingIndicator = styled(ProgressIndicator)``
