import TextBox from '@veneer/core/dist/scripts/text_box'
import styled from 'styled-components'
import Tokens from '@veneer/tokens'

export const FormInline = styled.div`
  display: flex;
`
export const EmailInfo = styled.div`
  div[class='vn-input css-paboqa'] {
    border-width: 1px;
  }
`

export const EmailItemContainer = styled.div`
  margin: 0;
`

export const EmailItem = styled(TextBox)`
  margin: 6px auto;

  && {
    border: none;
  }
  .added {
    /* this color and format will be aligned with designed team after */
    color: green;
    font-weight: bold;
  }
  @media (max-width: '600px') {
    && input[type='text'] {
      font-size: ${Tokens.fontSize3};
    }
  }
`

export const Header = styled.h4`
  margin-top: 0px;
  margin-bottom: ${Tokens.space5};
`

export const HeaderUsers = styled.h4`
  margin: 10px 0;
`
export const CancelInfo = styled.div`
  > Button[id='buttonInfo'] {
    background-color: white;
    color: ${Tokens.colorHpBlue6};
    border: 1px solid #027aae;
  }
`
