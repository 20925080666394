import { useCallback } from 'react'
import { SERVICES } from '../../constants/services'
import useApiCall from '../../hooks/useApiCall'

export const useUserData = (userTenantClient) => {
  const apiCall = useCallback(async () => {
    const mergedUsers = await userTenantClient.getMergedUsers()
    return mergedUsers?.data.sort((a, b) => {
      a.createdAt - b.createdAt
    })
  }, [])

  return useApiCall({ apiCall })
}

export const useProgramInfos = (userTenantClient, accountMgtSvcClient) => {
  const apiCall = useCallback(async () => {
    const accountInfo = await accountMgtSvcClient.getAccount()
    const currentActiveUser = await userTenantClient.getCurrentActiveUser()
    const programInfosResponse = await accountMgtSvcClient.getProgramInfos(
      accountInfo.data.resourceId,
      currentActiveUser.data.user.resourceId
    )

    const programInfoMapEntry =
      programInfosResponse.data?.planInfo?.programLevel === 'HP+'
        ? 'YETI'
        : 'UCDE'

    const doesUserHavePOTG = !!programInfosResponse.data?.programInfoMap?.[
      programInfoMapEntry
    ]?.entitlementList.find(
      (entitlement) => entitlement.serviceId === SERVICES.POTG.PATH
    )

    return {
      account: {
        resourceId: accountInfo.data.resourceId,
        type: accountInfo.data.type
      },
      currentActiveUser: {
        roleCategory: currentActiveUser.data.userTenantDetail.roleCategory
      },
      hasPOTG: doesUserHavePOTG
    }
  }, [])

  return useApiCall({ apiCall })
}
