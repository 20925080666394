import React from 'react'
import { LoadingContainer, LoadingIndicator } from './styles'

const LoaderWidget = () => {
  return (
    <LoadingContainer>
      <LoadingIndicator appearance="circular" behavior="indeterminate" />
    </LoadingContainer>
  )
}

export default LoaderWidget
