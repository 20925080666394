import React, { createContext } from 'react'
import { TranslatorFunctionType } from '../types/localization'

interface PropsType {
  children: React.ReactNode
  t: TranslatorFunctionType
}

interface MfeContextType {
  t: TranslatorFunctionType
}

export const MfeContext = createContext<MfeContextType>(null)

export const MfeProvider = ({ children, t }: PropsType) => {
  return <MfeContext.Provider value={{ t }}>{children}</MfeContext.Provider>
}
