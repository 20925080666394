const ACTIVITY = 'AcctMgmt-v01'
const EVENT_DETAIL_VERSION = '1.3.0'
const HOME_SCREEN_PATH = '/ReactHpxUsers/'
const SCREEN_PATH = '/ReactHpxUsers/Users/'
const EVENT_CATEGORY = 'simpleUi'
const EVENT_DETAIL_TYPE =
  'com.hp.cdm.domain.telemetry.type.eventDetail.category.simpleUi.version.1'

export const publishScreenDisplayedEvent = (analytics) => {
  analytics.publishCdmEvents([
    {
      dateTime: new Date().toISOString(),
      eventDetailType: EVENT_DETAIL_TYPE,
      eventCategory: 'simpleUi',
      eventDetail: {
        activity: ACTIVITY,
        version: EVENT_DETAIL_VERSION,
        screenPath: HOME_SCREEN_PATH,
        screenName: 'Users',
        action: 'ScreenDisplayed'
      },
      version: EVENT_DETAIL_VERSION
    }
  ])
}

export const publishButtonEvent = (
  analytics,
  screenName,
  action,
  controlName,
  actionAuxParams
) => {
  analytics.publishCdmEvents([
    {
      dateTime: new Date().toISOString(),
      eventDetailType: EVENT_DETAIL_TYPE,
      eventCategory: EVENT_CATEGORY,
      eventDetail: {
        activity: ACTIVITY,
        version: EVENT_DETAIL_VERSION,
        screenPath: SCREEN_PATH,
        screenName: `${screenName}`,
        action: action,
        actionAuxParams: actionAuxParams,
        controlName: controlName
      },
      version: EVENT_DETAIL_VERSION
    }
  ])
}

export const publishDisplayedEvent = (
  analytics,
  screenName,
  action,
  actionAuxParams
) => {
  analytics.publishCdmEvents([
    {
      dateTime: new Date().toISOString(),
      eventDetailType: EVENT_DETAIL_TYPE,
      eventCategory: EVENT_CATEGORY,
      eventDetail: {
        activity: ACTIVITY,
        version: EVENT_DETAIL_VERSION,
        screenPath: SCREEN_PATH,
        screenName: `${screenName}`,
        action: action,
        actionAuxParams: actionAuxParams
      },
      version: EVENT_DETAIL_VERSION
    }
  ])
}

export const publishModuleDisplayedEvent = (
  analytics,
  screenName,
  actionAuxParams
) => {
  analytics.publishCdmEvents([
    {
      dateTime: new Date().toISOString(),
      eventDetailType: EVENT_DETAIL_TYPE,
      eventCategory: EVENT_CATEGORY,
      eventDetail: {
        activity: ACTIVITY,
        version: EVENT_DETAIL_VERSION,
        screenPath: SCREEN_PATH,
        screenName: `${screenName}`,
        action: 'ModuleDisplayed',
        actionAuxParams: actionAuxParams
      },
      version: EVENT_DETAIL_VERSION
    }
  ])
}

export default publishScreenDisplayedEvent
