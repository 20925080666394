const errorCodes = [
  { code: 'E0010U0019', message: 'hpxUsers.inviteOverlay.alreadyHasAccount' },
  {
    code: 'EB000U0123',
    message: 'hpxUsers.inviteOverlay.userAssignedPersonalOrg'
  },
  { code: 'EB000A0124', message: 'hpxUsers.inviteOverlay.baseUserWasInvited' },
  { code: 'EB000U0004', message: 'hpxUsers.inviteOverlay.userAlreadyInvited' },
  { code: 'EB000U0109', message: 'hpxUsers.inviteOverlay.errorMessage' }
]

export const getErrorCode = (error) =>
  error?.data?.errors?.find((err) => errorCodes.find((e) => e.code == err.code))
    ?.code

export const createErrorMessage = (code) => {
  const errorCode = errorCodes.find((e) => e.code == code)
  return errorCode?.message || 'hpxUsers.inviteOverlay.errorMessage'
}

export const createSuccessMessage = () => {
  return 'hpxUsers.inviteOverlay.successMessage'
}
