import styled from 'styled-components'
import Modal from '@veneer/core/dist/scripts/modal'
import Tokens from '@veneer/tokens'
/*
 * Global modal definitions
 */
export const ModalContainer = styled(Modal)`
  .vn-modal--title {
    padding-bottom: ${Tokens.space5};
    margin: 0;
  }

  .css-paboqa input {
    background-color: ${Tokens.colorHpBlue0};
  }

  @media (max-width: 600px) {
    .vn-modal--dialog {
      .vn-modal--content {
        padding: ${Tokens.space5};
        h2 {
          padding-bottom: 10px;
        }

        button {
          padding: 6px 0;
          min-width: 80px;
          border-radius: 6px;
        }
        .vn-input__icon {
          padding-left: 0;
          button {
            min-width: ${Tokens.space6};
          }
        }
      }
    }
  }
`

export default ModalContainer
