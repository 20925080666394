import React, { useState, useEffect, useMemo } from 'react'
import UserInfoAccordion from './UserInfoAccordion'
import UserInfoTable from './UserInfoTable'
import { useTranslator } from '../../../hooks/useTranslator'

const UserInfo = ({ users, trashIconHandler, isLoading }) => {
  const t = useTranslator()

  const status = useMemo(
    () => ({
      Active: t('hpxUsers.table.status.active'),
      Pending: t('hpxUsers.table.status.pending'),
      Expired: t('hpxUsers.table.status.expired')
    }),
    [t]
  )

  const roles = useMemo(
    () => ({
      User: t('hpxUsers.table.typeOfUsers.user'),
      Admin: t('hpxUsers.table.typeOfUsers.owner')
    }),
    [t]
  )

  const [usersData, setUsersData] = useState([])

  useEffect(() => {
    if (users) {
      setUsersData(
        users.map((user) => ({
          ...user,
          roleText: roles[user.role],
          statusText: status[user.status]
        }))
      )
    }
  }, [roles, status, users])

  return (
    <>
      <UserInfoAccordion
        users={usersData}
        trashIconHandler={trashIconHandler}
        isLoading={isLoading}
      />
      <UserInfoTable
        users={usersData}
        trashIconHandler={trashIconHandler}
        isLoading={isLoading}
      />
    </>
  )
}

export default UserInfo
