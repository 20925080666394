import React, { createContext } from 'react'

import { AxiosResponse } from 'axios'

import { JarvisAuthProvider } from '@jarvis/web-http'

import {
  Stack,
  AccountMgtSvcClient,
  UserTenantMgtSvcClient
} from '@jarvis/web-stratus-client'

import { HTTP_STATUS_CODES } from '../constants/httpStatusCodes'

interface PropsType {
  stack: Stack

  children: React.ReactNode

  authProvider: JarvisAuthProvider
}

interface StratusType {
  accountMgtSvcClient: {
    getAccount: () => Promise<AxiosResponse>
    inviteUser: (accountId, email) => Promise<AxiosResponse>
    deleteUser: (accountId, userResourceId) => Promise<AxiosResponse>
    getProgramInfos: (
      accountId: string,
      userId: string
    ) => Promise<AxiosResponse>
  }
  userTenantClient: {
    getCurrentActiveUser: () => Promise<AxiosResponse>
    getMergedUsers: () => any
  }
}

export const StratusContext = createContext<StratusType>(undefined)

export const StratusProvider = ({
  children,
  stack,
  authProvider
}: PropsType) => {
  const accountClient = new AccountMgtSvcClient(stack, authProvider)
  const userTenantMgtSvcClient = new UserTenantMgtSvcClient(stack, authProvider)

  const accountMgtSvcClient = {
    getAccount: async (): Promise<AxiosResponse> => {
      try {
        const response = await accountClient.getAccount()
        return response
      } catch (error) {
        return error.response
      }
    },
    inviteUser: async ({ accountId, email }): Promise<AxiosResponse> => {
      try {
        const response = await accountClient.inviteUser(
          accountId,
          email,
          undefined
        )
        return response
      } catch (error) {
        return error.response
      }
    },
    deleteUser: async (accountId, userResourceId): Promise<AxiosResponse> => {
      try {
        const response = await accountClient.deleteUser(
          accountId,
          userResourceId
        )
        return response
      } catch (error) {
        return error.response
      }
    },
    getProgramInfos: async (
      accountId: string,
      userId: string
    ): Promise<AxiosResponse> => {
      try {
        return await accountClient.getProgramInfos(accountId, userId)
      } catch (error) {
        return error.response
      }
    }
  }

  const userTenantClient = {
    getCurrentActiveUser: async (): Promise<AxiosResponse> => {
      try {
        const response = await userTenantMgtSvcClient.getCurrentActiveUserWithTenant()
        return response
      } catch (error) {
        return error.response
      }
    },
    getMergedUsers: async () => {
      try {
        const tenantResourceIdResponse = await userTenantMgtSvcClient.getCurrentActiveUserWithTenant()
        const tenantResourceId =
          tenantResourceIdResponse.data.userTenantDetail.tenantResourceId

        const internalUserListResponse = await userTenantMgtSvcClient.getUserWithTenantList(
          tenantResourceId
        )

        const internalUsersList = internalUserListResponse.data.resourceList.map(
          (resource) => ({
            givenName: resource.user.givenName,
            familyName: resource.user.familyName,
            user: resource.user.givenName,
            email: resource.user.email.value,
            role: resource.userTenantDetail.roleCategory,
            status: resource.userTenantDetail.state,
            accountId: resource.userTenantDetail.tenantResourceId,
            resourceId: resource.user.resourceId,
            createdAt: resource.user.createdAt
          })
        )

        let externalUsersList
        try {
          const externalUserListResponse = await userTenantMgtSvcClient.getExternalUserList(
            tenantResourceId
          )
          externalUsersList = externalUserListResponse.data.resourceList.map(
            (resource) => ({
              givenName: null,
              familyName: null,
              user: resource.userName,
              email: resource.email.value,
              role: resource.roleCategory,
              status: resource.state,
              accountId: resource.tenantResourceId,
              resourceId: resource.resourceId,
              createdAt: resource.createdAt
            })
          )
        } catch (error) {
          externalUsersList = []
        }
        return {
          data: internalUsersList.concat(externalUsersList),
          status: HTTP_STATUS_CODES.SUCCESS.OK
        }
      } catch (error) {
        return error.response
      }
    }
  }

  return (
    <StratusContext.Provider
      value={{
        accountMgtSvcClient,
        userTenantClient
      }}
    >
      {children}
    </StratusContext.Provider>
  )
}
