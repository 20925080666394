import React, { useState, useContext, useEffect } from 'react'
import { StratusContext } from '../../contexts/stratusContext'
import UserInfo from './UserInfo/UserInfo'
import NoUsers from './NoUsers'
import Button from '@veneer/core/dist/scripts/button'
import useToast from '@veneer/core/dist/scripts/toast_container/use_toast'
import {
  Container,
  CardHeading,
  MemberTitle,
  AddMemberTitle,
  Text
} from './style'
import IconPlus from '@veneer/core/dist/scripts/icons/icon_plus'
import InviteUsers from '../InviteUsers/index'
import DeleteUser from '../DeleteUser/index'
import { useTranslator } from '../../hooks/useTranslator'
import {
  publishButtonEvent,
  publishDisplayedEvent,
  publishModuleDisplayedEvent,
  publishScreenDisplayedEvent
} from '../../utils/analytics'
import { HTTP_STATUS_CODES } from '../../constants/httpStatusCodes'
import ErrorWidget from '../shared-components/ErrorWidget/index'
import { useUserData, useProgramInfos } from './hooks'
import { useFlags } from 'launchdarkly-react-client-sdk'

const Content = (userInfo, programInfos, inviteUser, deleteUser, analytics) => {
  const t = useTranslator()

  const remModalInitialValues = {
    open: false,
    accountId: '',
    resourceId: '',
    email: '',
    role: '',
    status: ''
  }

  const [remModalProps, setRemModalProps] = useState(remModalInitialValues)
  const [disableRemoveBtn, setDisableRemoveBtn] = useState(false)
  const [showInviteUsers, setShowInviteUsers] = useState(false)

  const trashIconHandler = (user) => {
    publishDisplayedEvent(
      analytics,
      'RemoveUser',
      'ModalWindowDisplayed',
      `role=${user.role}&status=${user.status}`
    )
    setRemModalProps({
      open: true,
      accountId: user.accountId,
      resourceId: user.resourceId,
      email: user.email,
      role: user.role,
      status: user.status
    })
  }

  const removeModalHandler = () => {
    setRemModalProps(remModalInitialValues)
    publishButtonEvent(
      analytics,
      'RemoveUser',
      'ControlButtonClicked',
      'Cancel'
    )
  }

  const handleInviteUsersButton = () => {
    setShowInviteUsers(true)
    publishDisplayedEvent(analytics, 'InviteUsers', 'ModalWindowDisplayed')
  }

  const handleInviteUsersClose = () => {
    setShowInviteUsers(false)
  }

  const handleInviteUsersFinish = () => {
    setShowInviteUsers(false)
  }

  const removeUser = async () => {
    publishButtonEvent(
      analytics,
      'RemoveUser',
      'ControlButtonClicked',
      'Confirm',
      `role=${remModalProps.role}&status=${remModalProps.status}`
    )

    setDisableRemoveBtn(true)

    await deleteUser(
      remModalProps.accountId,
      remModalProps.resourceId,
      remModalProps.email,
      remModalProps.role,
      remModalProps.status
    )
    setRemModalProps(remModalInitialValues)
    setDisableRemoveBtn(false)
  }

  const isUserLoading = userInfo.isFetching || programInfos.isFetching
  const users = userInfo.data
  const accountId = programInfos.data?.account?.resourceId
  const getUpdatedUserData = userInfo.forceFetch

  return (
    <>
      <Container>
        <CardHeading>
          <MemberTitle className="title-small">
            {t('hpxUsers.topOfPage.mainHeaderV2')}
          </MemberTitle>
          <Text className="body">{t('hpxUsers.topOfPage.copy')}</Text>
          <AddMemberTitle className="caption">
            <Button
              leadingIcon={<IconPlus />}
              onClick={handleInviteUsersButton}
              disabled={isUserLoading}
            >
              {t('hpxUsers.button')}
            </Button>
          </AddMemberTitle>
        </CardHeading>
        <UserInfo
          isLoading={isUserLoading}
          users={users}
          trashIconHandler={trashIconHandler}
        />
        <InviteUsers
          show={showInviteUsers}
          onClose={handleInviteUsersClose}
          onFinish={handleInviteUsersFinish}
          users={users}
          accountId={accountId}
          inviteUser={inviteUser}
          getUpdatedUserData={getUpdatedUserData}
          analytics={analytics}
        />
        <DeleteUser
          show={remModalProps.open}
          onClose={removeModalHandler}
          disableRemoveBtn={disableRemoveBtn}
          removeUser={removeUser}
        />
      </Container>
    </>
  )
}

const UsersView = ({ analytics }) => {
  const t = useTranslator()
  const { accountMgtSvcClient, userTenantClient } = useContext(StratusContext)

  const userInfo = useUserData(userTenantClient)
  const programInfos = useProgramInfos(userTenantClient, accountMgtSvcClient)

  useEffect(() => {
    publishScreenDisplayedEvent(analytics)
  }, [analytics])

  const { addToast } = useToast()

  const showErrorPage = programInfos.error || userInfo.error

  const showLoadingPage = userInfo.isFetching || programInfos.isFetching

  const { hpxMyAccountUsersSharingControl } = useFlags()

  const showUsersPage =
    (userInfo.data?.length > 0 &&
      programInfos.data?.hasPOTG &&
      programInfos.data?.account.type === 'Personal' &&
      programInfos.data?.currentActiveUser.roleCategory === 'Admin') ||
    !hpxMyAccountUsersSharingControl

  const inviteUser = async (accountId, email) => {
    const accountDataResponse = await accountMgtSvcClient.inviteUser(
      accountId,
      email
    )
    return accountDataResponse
  }

  const deleteUser = async (accountId, userResourceId, email, role, status) => {
    try {
      const response = await accountMgtSvcClient.deleteUser(
        accountId,
        userResourceId
      )

      if (response.status === HTTP_STATUS_CODES.SUCCESS.OK) {
        publishModuleDisplayedEvent(
          analytics,
          'RemoveUserSuccess',
          `role=${role}&status=${status}`
        )
        successToast()
      } else {
        publishModuleDisplayedEvent(
          analytics,
          'RemoveUserError',
          `role=${role}&status=${status}&error=Response status: ${response.status}`
        )
        errorToast(email)
      }
      return response
    } catch (error) {
      publishModuleDisplayedEvent(
        analytics,
        'RemoveUserError',
        `role=${role}&status=${status}&error=${error}`
      )
      errorToast(email)
    } finally {
      await userInfo.forceFetch()
    }
  }

  const successToast = () => {
    addToast({
      id: `toast`,
      type: 'positive',
      text: t('hpxUsers.table.delete.successMessage')
    })
  }

  const errorToast = (email) => {
    addToast({
      id: `toast`,
      type: 'negative',
      text: t('hpxUsers.table.delete.errorMessage', { email })
    })
  }

  const userContent = Content(
    userInfo,
    programInfos,
    inviteUser,
    deleteUser,
    analytics
  )

  const forceFetch = () => {
    userInfo.forceFetch()
    programInfos.forceFetch()
  }

  if (showErrorPage) return <ErrorWidget refreshHandler={forceFetch} />

  if (showLoadingPage || showUsersPage) return userContent

  return <NoUsers />
}

export default UsersView
