import styled from 'styled-components'
import Tokens from '@veneer/tokens'

export const PageHeading = styled.div`
  display: flex;
  padding-top: 50px;
  justify-content: center;
  color: ${Tokens.colorGray10};
`

export const Messages = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 10px;
  color: ${Tokens.colorGray10};
`

export const TryAgainButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: ${Tokens.space5};
`
