import { useCallback, useEffect, useState } from 'react'

export default function useApiCall({
  apiCall = null,
  fetch = null,
  initialData = null,
  initialFetch = true,
  init = true,
  params = null
}) {
  const [data, setData] = useState(initialData)
  const [error, setError] = useState<unknown | null>(null)
  const [pending, setPending] = useState<boolean>(false)
  const [success, setSuccess] = useState<boolean | null>(null)

  const execute = useCallback(
    async (opts?: unknown) => {
      try {
        setPending(true)
        setError(null)
        setSuccess(null)

        const output = apiCall ? await apiCall(opts || params) : await fetch()

        setData(output)
        setSuccess(true)
      } catch (err) {
        setError(err)
        setSuccess(false)
        console.error(err)
      } finally {
        setPending(false)
      }
    },
    [apiCall, fetch, params]
  )

  const forceFetch = () => execute()
  const makeApiCall = (opts: unknown) => execute(opts)

  useEffect(() => {
    if (init && initialFetch) {
      execute()
    }
  }, [execute, initialFetch, init])

  return {
    data,
    error,
    forceFetch,
    isFetching: pending,
    makeApiCall,
    pending,
    success
  }
}
