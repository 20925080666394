import IconTrash from '@veneer/core/dist/scripts/icons/icon_trash'
import styled from 'styled-components'
import Tokens from '@veneer/tokens'
import Button from '@veneer/core/dist/scripts/button/button'

export const CardsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: baseline;
  flex-wrap: wrap;

  @media (max-width: ${Tokens.xsMax}) {
    display: none;
  }
`
export const AccordionContainer = styled.div`
  div.vn-animated-height__wrapper-inner > div {
    border: none;
    margin: 0;
    padding: 0;
  }

  @media (min-width: ${Tokens.smMin}) {
    display: none;
  }
`
export const AccordionTitle = styled.div``

export const ItemContainer = styled.div`
  :not(:nth-child(2)) {
    background-color: ${Tokens.colorGray0};
  }
  padding: ${Tokens.space3};
  color: ${Tokens.colorGray10};
  display: grid;
  align-items: center;
  text-align: justify;
  grid-template-columns: 64px auto;
`

export const ItemLabel = styled.div`
  text-transform: uppercase;
`

export const ItemValue = styled.div`
  word-break: break-word;
`

export const TableIconTrash = styled(Button)`
  && {
    float: right;
    padding: 5px 5px;
    color: ${Tokens.colorGray10};
  }
`

export const MobileIconTrash = styled(TableIconTrash)`
  && {
    margin: 10px 20px 10px;
  }
`
