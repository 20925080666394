import styled from 'styled-components'
import Tokens from '@veneer/tokens'
import Modal from '@veneer/core/dist/scripts/modal'

export const Textcontent = styled.span`
  font-family: ${Tokens.fontTextRegular};
`
export const Modaltext = styled.span`
  text-align: end;
`
export const ModalContainer = styled(Modal)`
  > .vn-modal--dialog > .vn-modal--content {
    border-color: ${Tokens.colorWhite};
    box-shadow: 1px 1px 2px ${Tokens.colorWhite};
  }
  > .vn-modal--dialog > .vn-modal--content > .vn-modal--title {
    padding-bottom: ${Tokens.space5};
    margin: 0;
  }
`
