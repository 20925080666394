import Table from '@veneer/core/dist/scripts/table'
import React, { useState, useEffect } from 'react'
import { CardsContainer, TableIconTrash } from '../UserInfo/style'
import Avatar from '@veneer/core/dist/scripts/avatar'
import { useTranslator } from '../../../hooks/useTranslator'
import { IconTrash } from '@veneer/core/dist/scripts/icons'

const UserInfoTable = ({ users, trashIconHandler, isLoading }) => {
  const t = useTranslator()

  const [tableData, setTableData] = useState([])

  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize] = useState(25)
  const indexOfLastPost = currentPage * pageSize
  const indexOfFirstPost = indexOfLastPost - pageSize
  const currentPosts = tableData.length
    ? tableData.slice(indexOfFirstPost, indexOfLastPost)
    : []

  const paginationProps = {
    currentPage,
    onPageChange: (pageNumber) => setCurrentPage(pageNumber),
    pageSize,
    totalItems: tableData?.length || 0
  }

  useEffect(() => {
    if (users) {
      setTableData(
        users.map((user) => ({
          avatar: (
            <Avatar
              label={
                (user.givenName &&
                  user.givenName[0].toUpperCase() + user.familyName &&
                  user.familyName[0].toUpperCase()) ||
                (user.email && user.email[0].toUpperCase())
              }
              size={36}
            />
          ),
          user:
            `${user.givenName || ''} ${user.familyName || ''}`.trim() || '-',
          email: user.email,
          role: user.roleText,
          status: user.statusText,
          trash:
            user.role == 'User' ? (
              <TableIconTrash
                appearance="ghost"
                leadingIcon={<IconTrash />}
                disabled={user.role == 'Admin'}
                onClick={() => trashIconHandler(user)}
              />
            ) : null
        })) || []
      )
    }
  }, [t, trashIconHandler, users])

  return (
    <CardsContainer>
      <Table
        columns={[
          {
            id: 'avatar',
            label: ''
          },
          {
            id: 'user',
            label: t('hpxUsers.tableHeaders.header1')
          },
          {
            id: 'email',
            label: t('hpxUsers.tableHeaders.header2')
          },
          {
            id: 'role',
            label: t('hpxUsers.tableHeaders.header4')
          },
          {
            id: 'status',
            label: t('hpxUsers.tableHeaders.header3'),
            sortable: false
          },
          {
            id: 'trash',
            label: ''
          }
        ]}
        data={currentPosts}
        loading={isLoading}
        loadingDataLength={pageSize}
        pagination={paginationProps}
        preferences={{
          width: [
            { columnId: 'avatar', width: 50 },
            { columnId: 'user', width: 250 },
            { columnId: 'email', width: 250 },
            { columnId: 'role', width: 100 },
            { columnId: 'status', width: 100 },
            { columnId: 'trash', width: 50 }
          ]
        }}
      />{' '}
    </CardsContainer>
  )
}

export default UserInfoTable
