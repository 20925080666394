import React, { useEffect, useState } from 'react'
import { TranslatorFunctionType } from './types/localization'
import { MfePropsType } from './types/mfeProps'
import UsersView from './components/UsersView'
import ToastProvider from '@veneer/core/dist/scripts/toast_container/toast_provider'
import { MfeProvider, StratusProvider } from './contexts'
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk'
import ErrorWidget from './components/shared-components/ErrorWidget'
import LoaderWidget from './components/shared-components/LoaderWidget'
import './styles/global.scss'

const App = ({
  t,
  stack,
  authProvider,
  analytics,
  properties
}: MfePropsType & { t: TranslatorFunctionType }) => {
  const [LDProvider, setLDProvider] = useState(null)
  const [fetchingLD, setFetchingLD] = useState(true)
  const [errorLD, setErrorLD] = useState(false)
  const launchDarklyClientId =
    properties?.launchDarklyClientId || '630cf8eb00abf711e3d0311f'

  useEffect(() => {
    const fetchFetureFlags = async () => {
      try {
        const Provider = await asyncWithLDProvider({
          clientSideID: launchDarklyClientId
        })
        setLDProvider(() => Provider)
      } catch (err) {
        console.error(err)
        setErrorLD(true)
      } finally {
        setFetchingLD(false)
      }
    }
    fetchFetureFlags()
  }, [launchDarklyClientId])

  if (fetchingLD) {
    return <LoaderWidget />
  }

  if (errorLD) {
    return <ErrorWidget />
  }

  return (
    <LDProvider
      clientSideID={launchDarklyClientId}
      user={{ key: 'test@email.com' }}
    >
      <MfeProvider t={t}>
        <StratusProvider stack={stack} authProvider={authProvider}>
          <ToastProvider>
            <UsersView analytics={analytics} />
          </ToastProvider>
        </StratusProvider>
      </MfeProvider>
    </LDProvider>
  )
}

export default App
