import React from 'react'
import PropTypes from 'prop-types'
import Button from '@veneer/core/dist/scripts/button'
import { ModalContainer, Modaltext, Textcontent } from './styles'
import ButtonGroup from '@veneer/core/dist/scripts/button_group'
import { useTranslator } from '../../hooks/useTranslator'

const DeleteUser = ({ show, onClose, disableRemoveBtn, removeUser }) => {
  const t = useTranslator()
  return (
    <ModalContainer
      classname="title-small"
      id="modal-id"
      show={show}
      onClose={onClose}
      footer={
        <Modaltext>
          <ButtonGroup>
            <Button onClick={onClose} appearance="secondary">
              {t('hpxUsers.table.delete.cancelButton')}
            </Button>
            <Button loading={disableRemoveBtn} onClick={removeUser}>
              {t('hpxUsers.table.delete.removeButton')}
            </Button>
          </ButtonGroup>
        </Modaltext>
      }
      title={t('hpxUsers.table.delete.modalTitle')}
    >
      <Textcontent>{t('hpxUsers.table.delete.modalBody')}</Textcontent>
    </ModalContainer>
  )
}

DeleteUser.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
}

export default DeleteUser
